import { useState, useEffect, useCallback } from "react";
import { client } from "../client";
import "./Ourstory.css";
const Ourstory = () => {
  const [isStoryLoading, setIsStoryLoading] = useState(false);
  const [storyData, setStoryData] = useState([]);

  const getAllStoryData = useCallback((rawData) => {
    const allStoryData = rawData.map((story) => {
      const { sys, fields } = story;
      const { id } = sys;
      const storyImage = fields.ourStoryImage.fields.file.url;
      const storyText = fields.ourStoryText;

      const updatedStory = {
        id,
        storyImage,
        storyText,
      };

      return updatedStory;
    });

    setStoryData(allStoryData);
  }, []);

  const getStoryData = useCallback(async () => {
    setIsStoryLoading(true);
    try {
      const res = await client.getEntries({ content_type: "ourStory" });
      const resData = res.items;
      if (resData) {
        getAllStoryData(resData);
      } else {
        setStoryData([]);
      }
      setIsStoryLoading(false);
    } catch (error) {
      console.error(error);
      setIsStoryLoading(false);
    }
  }, [getAllStoryData]);

  useEffect(() => {
    getStoryData();
  }, [getStoryData]);


  return (
    <div>
      <div className="video-responsive">
                  <iframe
                    width="853"
                    height="480"
                    src={'https://www.youtube.com/embed/IsH4mQcjvrw'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
      </div>
      <div>
      {storyData.map((item) => {
        const { storyImage, storyText } = item;
        return (
          <>
            <h2 className="ourstory-title" id="ourstory">Our Story</h2>
            <div className="ourstory-container">
              <p className="ourstory-text">{storyText}</p>
              <img src={storyImage} alt="our story" />
            </div>
          </>
        );
      })}
    </div>
    </div>
    
  );
};

export default Ourstory;
