import { useState, useEffect, useCallback } from "react";
import { client } from "../client";
import "./Testimonial.css";

const Testimonial = () => {
  const [isTestimonialLoading, setIsTestimonialLoading] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);

  const getAllTestimonialData = useCallback((rawData) => {
    const allTestimonialData = rawData.map((testimonial) => {
      const { sys, fields } = testimonial;
      const { id } = sys;
      const volunteerText = fields.ourTestimonialVolunteer;
      const donateText = fields.ourTestimonialDonate;

      const updatedTestimonial = {
        id,
        volunteerText,
        donateText,
      };

      return updatedTestimonial;
    });

    setTestimonialData(allTestimonialData);
  }, []);

  const getTestimonialData = useCallback(async () => {
    setIsTestimonialLoading(true);
    try {
      const res = await client.getEntries({ content_type: "testimonial" });
      const resData = res.items;
      if (resData) {
        getAllTestimonialData(resData);
      } else {
        setTestimonialData([]);
      }
      setIsTestimonialLoading(false);
    } catch (error) {
      console.error(error);
      setIsTestimonialLoading(false);
    }
  }, [getAllTestimonialData]);

  useEffect(() => {
    getTestimonialData();
  }, [getTestimonialData]);



  return (
    <div>
      {testimonialData.map((item) => {
        const { volunteerText, donateText } = item;
        return (
          <div className="testimonial-main-container" id="supportus">
            <div className="volunteer-container">
                <h3 className="volunteer-title">Volunteer</h3>
              <p className="volunteer-paragraph">{volunteerText}</p>
            </div>
            <div className="donate-container">
                <h3 className="donate-title">Donate</h3>
              <p className="donate-paragraph">{donateText}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Testimonial;
