import React from "react";
import "./Donate.css";
const Donate = () => {
  return (
    <div className="donate-maincontainer">
      <h1 className="donate-title">Donation</h1>
      <p className="donate-text">
        Every Week we're helping the less fortunate survive for another 24
        hours. Your donation goes buying hot food and the costs of getting our
        food and other donated goods to the less fortunate.
      </p>
      <p className="donate-text">
        There are no staff and everything is delivered by volunteers.
      </p>

      <h3 className="donate-subtitle">FINANCIAL DONATIONS</h3>
      <p className="donate-text">There are many ways you can donate</p>
      <div className="donate-block">
        <h3 className="donate-subtitle">Online</h3>

        <p className="donate-block-text">
          We accept single and regular donations through our online fundraising
          page. We accept all major credit cards, paypal and direct debit.
        </p>
        <button className="donate-button">
          <a
            href="https://cafdonate.cafonline.org/12145#!/DonationDetails"
            target="_blank"
            rel="noreferrer"
          >
            DONATE ONLINE
          </a>
        </button>

        <h3 className="donate-subtitle">In Person</h3>

        <p className="donate-block-text">
          You can make a cash donation at our Friday Food Distribution Session.
        </p>
        <h3 className="donate-subtitle">By Bank Transfer or Cheque</h3>

        <p className="donate-text">
          You can make a Bank transfer by sending us a cheque Metro Bank
        </p>
        <p className="donate-block-text-bold">
          Charity Begins At Home Sort Code <br />
          23-05-80 <br />
          Account Number 21865028
        </p>
      </div>
      <h3 className="donate-subtitle">Goods and Services</h3>
      <p className="donate-text">
        We also rely on the donation of non-perishable food and drinks as well
        as clothing and goods that can help rough sleepers survive.
      </p>
      <p className="donate-text">
        If you would like to donate goods or services please get in touch
        through any of the options on our contact page.
      </p>
    </div>
  );
};

export default Donate;
