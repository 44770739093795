import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo.jpg";
import { FaFacebook, FaTwitter,  FaYoutube, FaInstagram } from "react-icons/fa"
const Footer = () => {
  return (
    <div className="footer-container">
      <div className="inner-footer">
        <div className="footer-container-left">
          <div className="logo-footer">
            <Link to="./"><img src={logo} alt="logo" /></Link> 
          </div>
          <p className="copyright-text">
            © 2018-2019 Charity Begins At Home - Registered Charity Number
            1111079
          </p>
          <ul className="socialmedia">
             <li><a href="https://www.facebook.com/charitybeginsathome.org/" target="_blank" rel="noreferrer"><FaFacebook /></a></li>
              <li><a href="https://twitter.com/charitybeginsat" target="_blank" rel="noreferrer"><FaTwitter /></a></li>
              <li><a href="https://www.instagram.com/charitybeginsathomeofficial/" target="_blank" rel="noreferrer"><FaInstagram /></a></li>
              <li><a href="https://www.youtube.com/channel/UCsqAeJ_ZE9rjrnmGFX07auA" target="_blank" rel="noreferrer" ><FaYoutube /></a></li>
          </ul>
        </div>

        <div className="footer-container-middle">
          <h3>Contact Us</h3>
          <p>info@charitybeginsathome.org</p>
          <p>+44 (0) 7771 610151</p>
          <Link to="./contact"><button className="footer-btn">Contact Us</button></Link>
        </div>

        <div className="footer-container-right">
          <h3>Support Us</h3>
          <Link to="volunteer"><p className="footer-p">Volunteer</p></Link>
          <Link to="donate"><p className="footer-p">Donate</p></Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
