import './App.css';
import Home from './Home'
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer'
import { Routes, Route } from "react-router-dom"
import Volunteer from './components/Volunteer/Volunteer'
import Donate from './components/Donate/Donate'
import Contact from './components/Contact/Contact'
import OurProjects from './components/OurProjects/OurProjects';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/volunteer" element={ <Volunteer />} />
        <Route path="/donate" element={ <Donate /> } />
        <Route path="/contact" element={ <Contact />} />
        <Route path="/ourprojects" element={ <OurProjects /> } />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
