import { useState, useEffect, useCallback } from "react";
import { client } from "../client";
import { Link } from "react-router-dom";
import './Hero.css'
const Hero = () => {
  const [isHeroLoading, setIsHeroLoading] = useState(false);
  const [heroData, setHeroData] = useState([]);

  const getAllHeroData = useCallback((rawData) => {
    const allHeroData = rawData.map((hero) => {
      const { sys, fields } = hero;
      const { id } = sys;
      const heroContent = fields.heroContentBox;
      const heroImage = fields.heroImage.fields.file.url;
      const heroText = fields.heroText

      const updatedHero = {
        id,
        heroContent,
        heroImage,
        heroText
      };

      return updatedHero;
    });

    setHeroData(allHeroData);
  }, []);

  const getHeroData = useCallback(async () => {
    setIsHeroLoading(true);
    try {
      const res = await client.getEntries({ content_type: "hero" });
      const resData = res.items;
      if (resData) {
        getAllHeroData(resData);
      } else {
        setHeroData([]);
      }
      setIsHeroLoading(false);
    } catch (error) {
      console.error(error);
      setIsHeroLoading(false);
    }
  }, [getAllHeroData]);

  useEffect(() => {
    getHeroData();
  }, [getHeroData]);


  return (
    <div>
      {heroData.map((item) => {
        const {heroContent, heroImage, heroText } = item;
        return (
          <div className="hero-container">
            <img src={heroImage} alt="hero" />
            <div className="hero-heading-container">
              <p>{heroContent}</p>
              <p>{heroText}</p>
              <Link to="donate"><button className="hero-button">Donate today</button></Link>
            </div>
           
          </div>
        );
      })}
    </div>
  );
};

export default Hero;
