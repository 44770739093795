import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
const Map = () => {
  return (
    <MapContainer center={[51.509579, -0.124220]} zoom={15} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[51.509579, -0.124220]}>
        <Popup>
          <strong>Weekly Food Distribution</strong> <br />
          Every Friday evening from 6pm our volunteers give food to the less fortunate <br />
          <a href="https://www.google.com/maps/dir/?api=1&destination=6%20Agar%20St,%20Charing%20Cross,%20London%20WC2R%200JS,%20UK" target="_blank" rel="noreferrer">directions</a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
