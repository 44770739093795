import { useState, useEffect, useCallback } from "react";
import { client } from "../client";
import "./Feature.css";
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from "react-router-dom";

const Feature = () => {
  const [isFeatureLoading, setIsFeatureLoading] = useState(false);
  const [featureData, setFeatureData] = useState([]);

  const getAllFeatureData = useCallback((rawData) => {
    const allFeatureData = rawData.map((feature) => {
      const { sys, fields } = feature;
      const { id } = sys;
      const feature1Text = fields.feature1Text;
      const feature1Image = fields.feature1Image.fields.file.url;
      const feature2Text = fields.feature2Text;
      const feature2Image = fields.feature2Image.fields.file.url;

      const updatedFeature = {
        id,
        feature1Text,
        feature1Image,
        feature2Text,
        feature2Image,
      };

      return updatedFeature;
    });
    setFeatureData(allFeatureData);
  }, []);

  const getFeatureData = useCallback(async () => {
    setIsFeatureLoading(true);
    try {
      const res = await client.getEntries({ content_type: "feature" });
      const resData = res.items;
      if (resData) {
        getAllFeatureData(resData);
      } else {
        setFeatureData([]);
      }
      setIsFeatureLoading(false);
    } catch (error) {
      console.error(error);
      setIsFeatureLoading(false);
    }
  }, [getAllFeatureData]);

  useEffect(() => {
    getFeatureData();
  }, [getFeatureData]);


  return (
    <div>
      {featureData.map((item) => {
        const { feature1Text, feature1Image, feature2Text, feature2Image } =
          item;
        return (
          <div className="feature-container" id="whatwedo">
              <h3 className="feature1-title">Food Distribution</h3>
            <div className="feature1-container">
              <p className="feature1-text">{feature1Text}
              <Link to="volunteer"><p className="feature1-anchor">Find out how and where to volunteer... <p className="feature1-anchor1"><BsArrowRightShort /></p></p></Link>
              </p>
              
              <img src={feature1Image} alt="feature 1" />
            </div>
            <h3 className="feature2-title">
              Food Banks (Mobile and Smart Food Banks)
              </h3>
            <div className="feature2-container">
              <img src={feature2Image} alt="feature 1" />
              <p className="feature2-text">{feature2Text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Feature;
