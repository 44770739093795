import { useState, useEffect, useCallback } from "react";
import { client } from "../client";

import './News.css'

const News = () => {
  const [isNewsLoading, setIsNewsLoading] = useState(false);
    const [newsData, setNewsData] = useState([])

    const getAllNewsData = useCallback((rawData) => {
        const allNewsData = rawData.map((newsData) => {
          const { sys, fields } = newsData
          const { id } = sys;
          const newsImage = fields.newsImage.fields.file.url;
          const newsImage1 = fields.newsImage1.fields.file.url;
          const newsImage2 = fields.newsImage2.fields.file.url;
          const newsImage3 = fields.newsImage3.fields.file.url;
          const newsVideo = fields.newsVideo;

          const updatedNews = {
            id,
            newsImage,
            newsImage1,
            newsImage2,
            newsImage3,
            newsVideo
          };
    
          return updatedNews;
        });
        console.log(allNewsData);
        setNewsData(allNewsData);
      }, []);

      const getNewsData = useCallback(async () => {
        setIsNewsLoading(true);
        try {
          const res = await client.getEntries({ content_type: "news" });
          const resData = res.items;
          if (resData) {
            getAllNewsData(resData);
          } else {
            setNewsData([]);
          }
          setIsNewsLoading(false);
        } catch (error) {
          console.error(error);
          setIsNewsLoading(false);
        }
      }, [getAllNewsData]);
    
      useEffect(() => {
        getNewsData();
      }, [getNewsData]);
    
      console.log(newsData);

      return (
        <div>
          {newsData.map((item) => {
            const {newsImage, newsImage1, newsImage2, newsImage3 } = item;
            return (
              <div>
                <h3 className="news-title" id="gallery">Gallery</h3>
                <div className="news-container">
                  <img src={newsImage} alt="news" />
                  <img src={newsImage1} alt="news" />
                  <img src={newsImage2} alt="news" />
                  <img src={newsImage3} alt="news" />
              </div>
              </div>
              
            );
          })}
        </div>
      );
};

export default News;