import React from 'react'
import { BsTelephone} from 'react-icons/bs'
import { GoLocation, GoMail } from 'react-icons/go'
import './Contact.css'
const Contact = () => {
  return (
    <div className="contact">
      <h3 className="contact-title">Contact Us</h3>
      <p className="contact-subtitle">If you would like to get in touch, you can reach us using any of the methods below.</p>
      <p className="contact-phone">
        <p className="icon"><BsTelephone /> </p>+44 (0) 7771 610151
      </p>
      <p className="contact-mail">
        <p className="icon"><GoMail /></p> info@charitybeginsathome.org
      </p>
    </div>
  )
}

export default Contact