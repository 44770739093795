import Map from "./Map";
import "./Volunteer.css";
const Volunteer = () => {
  return (
    <div className="Volunteer">
      <h3 className="volunteer-title">VOLUNTEER WITH US</h3>
      <p className="volunteer-description">
        We are constantly looking for passionate volunteers as they are like
        fresh blood, excitement and energy to assist us on our journey to help
        those in need. You can join us every Friday and Wednesday evening from 5
        pm at Agar Street Strand London near Charing Cross Station. You can
        volunteer as often as possible, as we have a flexible volunteer policy
        without regular commitments.​ We are a purely volunteer-based charity
        without any paid staff even our founder is a volunteer, and we highly
        value our volunteers; that's why we are a CBAH Family. Don't hesitate to
        get in touch with us if you would also like to be part of this
        tremendous charitable initiative. Together we can make a difference and
        change the world.
      </p>
      <Map />
    </div>
  );
};

export default Volunteer;
