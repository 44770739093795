import React from "react";
import Hero from "./components/Hero/Hero";
import News from "./components/News/News";
import Feature from "./components/Feature/Feature";
import Ourstory from "./components/OurStory/Ourstory";
import Testimonial from "./components/Testimonial/Testimonial";

const Home = () => {
  return (
    <div>
      <Hero />
      <Ourstory />
      <Testimonial />
      <Feature />
      <News />
    </div>
  );
};

export default Home;
