import { useState, useEffect, useCallback } from "react";
import { client } from "../client";

import './OurProjects.css'

const OurProjects = () => {
  const [isOurprojectsLoading, setIsOurprojectsLoading] = useState(false);
    const [ourprojectsData, setOurprojectsData] = useState([])

    const getAllOurprojectsData = useCallback((rawData) => {
        const allOurprojectsData = rawData.map((ourproject) => {
          const { sys, fields } = ourproject
          const { id } = sys;
          const title = fields.title;
          const title1 = fields.title1;
          const title2 = fields.title2;
          const title3 = fields.title3;
          const ourprojects = fields.ourprojects;
          const ourprojects1 = fields.ourprojects1;
          const ourprojects2 = fields.ourprojects2;
          const ourprojects3 = fields.ourprojects3;
         
          const updatedOurprojects = {
            id,
            title,
            title1,
            title2,
            title3,
            ourprojects,
            ourprojects1,
            ourprojects2,
            ourprojects3,
          };
    
          return updatedOurprojects;
        });
        console.log(allOurprojectsData);
        setOurprojectsData(allOurprojectsData);
      }, []);

      const getOurprojectsData = useCallback(async () => {
        setIsOurprojectsLoading(true);
        try {
          const res = await client.getEntries({ content_type: "ourproject" });
          const resData = res.items;
          if (resData) {
            getAllOurprojectsData(resData);
          } else {
            setOurprojectsData([]);
          }
          setIsOurprojectsLoading(false);
        } catch (error) {
          console.error(error);
          setIsOurprojectsLoading(false);
        }
      }, [getAllOurprojectsData]);
    
      useEffect(() => {
        getOurprojectsData();
      }, [getOurprojectsData]);
    
      console.log(ourprojectsData);

      return (
        <div>
          {ourprojectsData.map((item) => {
            const {title, title1, title2, title3, ourprojects, ourprojects1, ourprojects2, ourprojects3} = item;
            return (
              <div className="ourprojects-container">
                <h3 className="ourprojects-title">{title}</h3>
                <p className="ourprojects-projects">{ourprojects}</p>
                <h3 className="ourprojects-title">{title1}</h3>
                <p className="ourprojects-projects">{ourprojects1}</p>
                <h3 className="ourprojects-title">{title2}</h3>
                <p className="ourprojects-projects">{ourprojects2}</p>
                <h3 className="ourprojects-title">{title3}</h3>
                <p className="ourprojects-projects">{ourprojects3}</p>
              </div>
              
            );
          })}
        </div>
      );
};

export default OurProjects;