const menuItems = [
  {
    title: "Home",
    url: "./",
    cName: "nav-links",
  },
  {
    title: "Our Story",
    url: "./#ourstory",
    cName: "nav-links",
  },
  {
    title: "Support Us",
    url: "./#supportus",
    cName: "nav-links",
  },
  {
    title: "What We Do",
    url: "./#whatwedo",
    cName: "nav-links",
  },
  {
    title: "Gallery",
    url: "./#gallery",
    cName: "nav-links",
  },
  {
    title: "Our Projects",
    url: "./ourprojects",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "./contact",
    cName: "nav-links",
  },
  {
    title: "Donate",
    url: "./donate",
    cName: "nav-links-mobile",
  },
  {
    title: "Volunteer",
    url: "./volunteer",
    cName: "nav-links-mobile",
  },
];

export default menuItems;
